//维修
export const repairColumn = [
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "taskItemStatus",
    fieldLabel: "任务项状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  // {
  //   display: true,
  //   fieldName: "contact",
  //   fieldLabel: "联系人",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  // {
  //   display: true,
  //   fieldName: "contactPhone",
  //   fieldLabel: "联系电话",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondName",
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "centerId",
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "questionType",
    fieldLabel: "问题类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "questionDesc",
    fieldLabel: "问题描述",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "reason",
    fieldLabel: "诊断问题",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "treatment",
    fieldLabel: "处理方案",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operator",
    fieldLabel: "操作人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "operatorTimeStr",
    fieldLabel: "操作时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//场景变更
export const changeSceneColumn = [
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "原场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "原场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "newSceneName",
    fieldLabel: "新场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operator",
    fieldLabel: "操作人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operatorTimeStr",
    fieldLabel: "操作时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//开通申请
export const openColumn = [
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "isChangeCard",
    fieldLabel: "是否换卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "taskItemStatus",
    fieldLabel: "任务项状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondName",
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "simNo",
    fieldLabel: "SIM卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "simType",
    fieldLabel: "SIM卡类型",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "supplier",
    fieldLabel: "所属供应商",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "stopDateStr",
    fieldLabel: "卡报停时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "expactDateStr",
    fieldLabel: "要求开通时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operator",
    fieldLabel: "操作人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operatorTimeStr",
    fieldLabel: "操作时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//报停申请
export const stopColumn = [
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "itemStatus",
    fieldLabel: "任务项状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "cardTreatment",
    fieldLabel: "是否拆机",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondName",
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  // {
  //   display: true,
  //   fieldName: "realNo",
  //   fieldLabel: "中心识别码",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  {
    display: true,
    fieldName: "simNo",
    fieldLabel: "SIM卡号",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "expactDate",
    fieldLabel: "要求报停时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "stopReason",
    fieldLabel: "报停原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operator",
    fieldLabel: "操作人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operatorTimeStr",
    fieldLabel: "操作时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//设置打印信息
export const setPrintColumn = [
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "taskItemStatus",
    fieldLabel: "任务项状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "icNo",
    fieldLabel: "IC卡号",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "driverNo",
    fieldLabel: "驾驶证号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "driverName",
    fieldLabel: "驾驶员姓名",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "frameNo",
    fieldLabel: "车架号",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "engineNo",
    fieldLabel: "发动机号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "licenseType",
    fieldLabel: "车牌分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "organization",
    fieldLabel: "发证机构",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "validTill",
    fieldLabel: "证件有效期",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//对接
export const dockColumn = [
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "taskItemStatus",
    fieldLabel: "任务项状态",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "dockName",
    fieldLabel: "对接平台",
    width: 200,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "remark",
    fieldLabel: "备注",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operator",
    fieldLabel: "操作人",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "operatorTimeStr",
    fieldLabel: "操作时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//咨询回复
export const replyColumn = [
  {
    display: true,
    fieldName: "id",
    fieldLabel: "工单ID",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneName",
    fieldLabel: "场景名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "sceneType",
    fieldLabel: "场景类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    display: true,
    fieldName: "equipName",
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipType",
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondName",
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "secondName",
    fieldLabel: "商品类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "equipCode",
    fieldLabel: "设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "simNo",
    fieldLabel: "sim卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    display: true,
    fieldName: "centerId",
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
