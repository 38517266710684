<!--
 * @Description: 设置打印信息
 * @Author: ChenXueLin
 * @Date: 2021-11-25 17:29:12
 * @LastEditTime: 2022-01-13 18:42:03
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="task-list-content">
      <detail-table-title @resize="handleTableTitleResize" :showSearch="false">
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns>
          <el-button
            type="primary"
            @click="handleFinish"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >处理完成</el-button
          >
          <el-button
            type="primary"
            @click="refused"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            >拒绝</el-button
          >
        </template>
      </detail-table-title>
      <!-- 表格 -->
      <el-table
        border
        height="300px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :row-key="
          row => {
            return row.id;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
          :selectable="checkSelectable"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'isChangeCard'">
              <e6-vr-select
                :data="isChangeList"
                :is-title="true"
                v-model="row.isChangeCard"
                clearable
                allow-create
              >
              </e6-vr-select>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="80">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList()"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 任务项 end -->
    <!--拒绝开通弹框  -->
    <el-dialog
      v-dialogDrag
      title="拒绝"
      :visible="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <!-- 拒绝原因 -->
      <el-form
        :inline="true"
        label-width="120"
        :model="refuseForm"
        :rules="refuseFormRules"
        ref="refuseForm"
      >
        <el-form-item label="拒绝原因" prop="remark">
          <el-input
            placeholder="拒绝原因"
            v-model="refuseForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { setPrintColumn } from "../../tableColumnData";
import { getPrintService, refusePrint, finishPrint } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  name: "repairService",
  data() {
    return {
      tableData: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      columnData: setPrintColumn,
      total: 0,
      selColumn: [], //勾选的任务项
      /******同意/拒绝报停************/
      dialogTitile: "", //弹框标题
      agreeList: [], //需要换卡的任务项
      refusedList: [], //不需要换卡的任务项
      stopType: 1, //1同意2拒绝
      dialogVisible: false,
      refuseForm: {
        remark: "" //拒绝原因
      },
      refuseFormRules: {
        remark: [
          {
            required: true,
            message: "请输入拒绝原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    DetailTableTitle
  },
  props: ["serviceId", "basicInfo"],
  watch: {},
  created() {
    this.getPrintServiceReq();
  },
  methods: {
    //查询设置打印任务项列表
    async getPrintServiceReq() {
      try {
        let res = await getPrintService({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        this.tableData = res.data.array;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //勾选任务项
    handleSelectionChange(val) {
      this.selColumn = val;
    },
    //待处理状态才能进行处理
    checkSelectable(row) {
      return row.taskItemStatusCode == 1;
    },
    //点击处理完成
    handleFinish() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.$confirm("是否确认完成所选任务项?", "处理完成", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.handleFinishPrint();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //处理完成接口
    async handleFinishPrint() {
      try {
        let res = await finishPrint({
          serviceId: this.serviceId,
          itemIds: this.selColumn.map(item => {
            return item.id;
          })
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.selColumn = [];
          this.$refs.multipleTable.clearSelection();
          this.getPrintServiceReq();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //拒绝
    refused() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.dialogVisible = true;
    },
    //拒绝确认
    confirm() {
      this.$refs.refuseForm.validate(valid => {
        if (valid) {
          this.handleRefusePrint();
        }
      });
    },
    //拒绝设置打印接口请求
    async handleRefusePrint() {
      try {
        let res = await refusePrint({
          serviceId: this.serviceId,
          itemIds: this.selColumn.map(item => {
            return item.id;
          })
        });
        if (res.code == "OK") {
          this.$message.success("拒绝成功");
          this.handleClose();
          this.getPrintServiceReq();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.refuseForm = {
        remark: ""
      };
      this.selColumn = [];
      this.$refs.multipleTable.clearSelection();
      this.$refs.refuseForm.resetFields();
      this.dialogVisible = false;
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "下发指令",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      console.log(val, row);
      if (val.id == 1) {
        window.open("http://devman.e6yun.com", "_blank");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    .title {
      margin-bottom: 15px;
    }
    .el-input {
      width: 280px;
    }
  }
}
</style>
