<!--
 * @Description: 详情页筛选框
 * @Author: ChenXueLin
 * @Date: 2021-10-12 17:41:12
 * @LastEditTime: 2021-11-18 09:50:07
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="table-title" ref="tableTitle">
    <!-- 搜索表单的插槽 start -->
    <section class="fixed-section">
      <!-- 搜索图标那行样式 -->
      <div class="table-title-content">
        <div class="table-title-btn">
          <slot name="title"></slot>
          <div class="btns">
            <slot name="btns"></slot>
            <i
              v-if="showSearch"
              class="e6-icon-search_line"
              title="搜索"
              @click="handleToggleSearchForm"
            ></i>
          </div>
        </div>
      </div>
    </section>
    <!--筛选内容的插槽 -->
    <section
      class="search-wrapper fixed-section search-triangle"
      v-show="searchFormVisiable"
    >
      <!-- 搜索表单的插槽 -->
      <slot name="searchForm"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "tableTitle",
  // mixins: [listPageReszie],
  data() {
    return {
      searchFormVisiable: false
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleToggleSearchForm() {
      this.searchFormVisiable = !this.searchFormVisiable;
      this.$emit("resize");
    }
  }
};
</script>

<style lang="scss" scoped>
.search-triangle {
  position: relative;
}
</style>
